<template>
  <nav
    class="hidden px-3 lg:flex"
  >
    <a
      class="bu-nav-link mr-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      href="/biz/training/course"
    >
      研修一覧
    </a>
    <a
      class="bu-nav-link mr-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      href="/biz/training/case"
    >
      導入事例
    </a>
    <div
      class="mx-5 flex items-center"
    >
      <a
        href="/biz/training/contact"
        class="bu-btn-contact"
      >お問い合わせ</a>
    </div>
    <span class="border-l border-co-gray-300" />
    <a
      class="bu-nav-link ml-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      href="https://training.techacademy.jp/recruit"
      target="_blank"
    >
      採用支援
    </a>
    <a
      class="bu-nav-link ml-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      href="/"
    >
      個人向け
    </a>
  </nav>
</template>

<style scoped>
@import url("~/assets/css/Bu/BuCommon/BuCommonButton.css");

/* リンク下の線 */
.bu-nav-link {
  position: relative;
  @media (max-width: 1199.98px) {
    font-size: 0.875rem;
  }
  &::after {
    position: absolute;
    bottom: 0.875rem;
    left: 0;
    width: 100%;
    height: 3px;
    content: '';
    @apply bg-co-primary-500;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(6px);
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
