<template>
  <div>
    <!-- SPナビゲーション開閉ボタン -->
    <button
      class="flex items-center px-5 text-[2rem] lg:hidden"
      @click="isMenuOpen = !isMenuOpen"
    >
      <i
        class="fa fa-bars"
        aria-hidden="true"
      />
    </button>

    <!-- 開閉メニュー -->
    <Transition name="fade">
      <div
        v-show="isMenuOpen"
        class="bu-collapse flex flex-col lg:hidden"
      >
        <a
          href="/biz/training/course"
          class="co-display-11 block w-full px-4 py-6 text-co-gray-700 hover:text-co-gray-700"
        >
          研修一覧
        </a>
        <a
          href="/biz/training/case"
          class="co-display-11 block w-full px-4 pb-6 pt-3 text-co-gray-700 hover:text-co-gray-700"
        >
          導入事例
        </a>
        <span class="border-t border-gray-300" />
        <a
          href="https://training.techacademy.jp/recruit"
          target="_blank"
          class="co-display-11 block w-full px-4 pb-3 pt-6 text-co-gray-700 hover:text-co-gray-700"
        >
          採用支援
        </a>
        <a
          href="/"
          class="co-display-11 block w-full px-4 py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          個人向け
          <i
            class="fa fa-angle-right ml-3"
            aria-hidden="true"
          />
        </a>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const isMenuOpen = ref(false);
</script>

<style scoped>
/* 開閉ナビゲーション */
.bu-collapse {
  @apply absolute top-[70px] left-0 w-full h-[calc(100vh-70px)] border-b border-gray-300 overflow-scroll;
  background-color: rgba(255, 255, 255, 0.97);
}

/* 開閉メニュー */
.collapse[open] > :where(.collapse-content) {
  @apply pb-0;
}

/* フェードアニメーション */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
