<template>
  <div>
    <TheBuCommonHeaderSimple />
    <main class="pt-[4.375rem]">
      <slot />
    </main>
    <TheTeCommonFooter />
  </div>
</template>

<script setup lang="ts">
import TheBuCommonHeaderSimple from '~/components/Bu/BuCommon/Header/TheBuCommonHeaderSimple/TheBuCommonHeaderSimple.vue'
import TheTeCommonFooter from '~/components/Te/TeCommon/Footer/TheTeCommonFooter/TheTeCommonFooter.vue'

useHead({
  htmlAttrs: {
    'data-theme': 'techacademy',
  },
})
</script>

