import { dateToYesterday, daysOfWeek } from '~/utility/utility'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      yesterdayMonth(date: string): number {
        return dateToYesterday(new Date(date)).getMonth() + 1
      },
      yesterdayDays: (date: string): number => {
        return dateToYesterday(new Date(date)).getDate()
      },
      hours(date: string): number {
        return new Date(date).getHours() + 24
      },
      yesterdayDayOfWeek(date: string): '日' | '月' | '火' | '水' | '木' | '金' | '土' {
        const yesterday = dateToYesterday(new Date(date))
        const dayOfWeek = daysOfWeek(yesterday)
        return dayOfWeek
      },
    }
  }
})
